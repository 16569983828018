import { store } from '../heineken_template/_private/store';
export const fubon_initStyling = (templateProps) => {
    store.charting.darkOverrides = {
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.candleStyle.borderDownColor': '#6ba583',
        'mainSeriesProperties.candleStyle.borderUpColor': '#d75442',
        'mainSeriesProperties.candleStyle.downColor': '#6ba583',
        'mainSeriesProperties.candleStyle.upColor': '#d75442',
        'mainSeriesProperties.candleStyle.wickDownColor': '#c2c2c3',
        'mainSeriesProperties.candleStyle.wickUpColor': '#c2c2c3',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'paneProperties.topMargin': 10,
        'paneProperties.bottomMargin': 10,
        'paneProperties.vertGridProperties.color': '#000000',
        'paneProperties.horzGridProperties.color': '#000000',
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#000000',
        'paneProperties.legendProperties.showSeriesOHLC': true,
        'timeScale.rightOffset': 5,
        'scalesProperties.fontSize': 16,
        'scalesProperties.textColor': '#f8f9f9',
        'scalesProperties.lineColor': '#cccccc',
        'scalesProperties.showSeriesLastValue': true,
        'scalesProperties.showStudyLastValue': false,
    };
};
