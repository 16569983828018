import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const bbw0 = createIndicator({
    id: 'fubon-bbw0',
    displayName: '波動指標',
    constructorScope: {
        init() {
            return;
        },
        main(context, inputCallback) {
            this._context = context;
            this._input = inputCallback;
            const PineJS = this.PineJS;
            this.f_0 = (e, t) => {
                return e * t;
            };
            this.f_1 = (e, t) => {
                return e + t;
            };
            this.f_2 = (e, t) => {
                return e - t;
            };
            this.f_3 = (e, t) => {
                return (e - t) / i;
            };
            const i = PineJS.Std.close(this._context);
            const o = 18;
            const r = 2;
            const s = this._context.new_var(i);
            const a = PineJS.Std.sma(s, o, this._context);
            const l = this._context.new_var(i);
            const c = PineJS.Std.stdev(l, o, this._context);
            const u = this.f_0(r, c);
            const h = this.f_1(a, u);
            const d = this.f_2(a, u);
            return [1000 * this.f_3(h, d, a)];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 1,
                    trackPrice: !1,
                    transparency: 25,
                    visible: !0,
                    color: '#26c6da',
                },
            },
            precision: 2,
            inputs: {},
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                title: 'Plot',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});
