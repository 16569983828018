import dayAPI from '~/utils/dayAPI';
import { store } from '~/pages/heineken_template/_private/store';
import { st0 } from '~/trades/indicators/fubon/st0_oldreact';
import { st1 } from '~/trades/indicators/fubon/st1_oldreact';
import { lrc0 } from '~/trades/indicators/fubon/lrc0';
import { wr0 } from '~/trades/indicators/fubon/wr0_oldreact';
import { bbw0 } from '~/trades/indicators/fubon/bbw0';
export const fubon_initStrategies = () => {
    store.charting.initStrategy({
        configs: [
            {
                displayName: '主要',
                interval: '5',
                symbol: 'TX-1',
                indicators: [st0, st1, lrc0, bbw0, wr0],
                calcFrom: dayAPI().subtract(7, 'day'),
                panesRatio: 60,
            },
        ],
    });
};
